<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">ADD SONG</p>
    </div>
    <div class="dialog-content" style="overflow-y: auto; max-height: 560px;">
      <b-container>
        <label for="audioFile">Song:</label>
        <b-form-file id="audioFile"
                     accept="audio/*"
                     aria-describedby="audio-file"
                     placeholder="Choose a file..."
                     ref="audioFileInput"
                     v-model="audioFile"
                     @input="$v.audioFile.$touch()"
                     :state="!$v.audioFile.$error">
        </b-form-file>
        <b-form-invalid-feedback id="audio-file" v-if="$v.audioFile.$error">
          <span v-if="!$v.audioFile.uniqueSelectedSong">
            Selected song is already added.
          </span>
          <span v-if="!$v.audioFile.validSongType">
            Selected song type is not valid. Please select mp3 file.
          </span>
        </b-form-invalid-feedback>

        <!-- Progress Bar -->
        <div class="mt-2" v-if="uploading">
          <b-progress :value="progress" :max="max"
                      variant="success"
                      show-progress animated>
          </b-progress>
        </div>

        <!-- Buttons -->
        <div class="d-flex justify-content-end mt-3 mb-3">
          <b-button variant="outline-success"
                    :disabled="$v.$invalid"
                    @click="uploadSong">
            Upload
          </b-button>
          <b-button class="ml-2"
                    variant="outline-danger"
                    @click="$emit('close')">
            Cancel
          </b-button>
        </div>

        <!-- Loader -->
        <loading :active.sync="uploading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.5"
                 :width="70"
                 :height="70"
        ></loading>
      </b-container>
    </div>

  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');
  import { mapActions } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name  : 'AddSong',
    props : {
      album : Object,
    },
    data() {
      return {
        audioFile          : null,
        uploading          : false,
        progress           : 0,
        max                : 100,
        uniqueSelectedSong : true,
        validSongType      : true,
      };
    },
    watch : {
      audioFile(value) {
        this.uniqueSelectedSong = true;
        this.validSongType = true;
        if (value) {
          if (value.type === 'audio/mp3' || value.type === 'audio/mpeg')
            this.checkSongName();
          else
            this.validSongType = false;
        } else
          this.$v.$reset();
      },
    },
    methods : {
      ...mapActions({
        'getChallengesAlbumSongs' : 'media/getChallengesAlbumSongs',
      }),

      /**
       * Check if Song Name is unique
       */
      // eslint-disable-next-line no-undef
      checkSongName : _.debounce(function() {
        // eslint-disable-next-line no-undef
        const filename = _.split(this.audioFile.name, '.');
        this.$http.get('/mobile/api/challenges_album/songname', {
          params : {
            songName : filename[0],
            albumId  : this.album.challenges_album_id,
          },
        }).then(response => {
          this.uniqueSelectedSong = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Upload Song
       */
      async uploadSong() {

        this.$v.audioFile.$touch();

        if (!this.$v.audioFile.$error) {
          const formData = new FormData();
          formData.append('file', this.audioFile);
          formData.append('albumName', this.album.album_name);
          formData.append('albumId', this.album.challenges_album_id);
          try {
            this.uploading = true;
            const result =
              await this.$http.post('/api/add/challenges_mp3', formData, {
                onUploadProgress : e =>
                  this.progress = Math.round(e.loaded * 100 / e.total),
              });
            if (result) {
              this.uploading = false;
              this.progress = 0;
              this.$refs.audioFileInput.reset();
              // eslint-disable-next-line no-undef
              const filename = _.split(this.audioFile.name, '.');
              this.$http.post('/api/notification/admin/new_music', {
                mobileAlbumId : this.album.challenges_album_id,
              }).then(result => {
                console.log(result, ' new music')
              });
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success!',
                text  : 'Added ' + filename[0] + ' successfully!',
              });
              this.getChallengesAlbumSongs(this.album.challenges_album_id);
              this.$emit('close');
            }
          } catch (err) {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
            this.uploading = false;
          }
        }

      },
    },
    components : {
      Loading,
    },
    validations : {
      audioFile : {
        required,
        uniqueSelectedSong() {
          return this.uniqueSelectedSong;
        },
        validSongType() {
          return this.validSongType;
        },
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/challenges_music/add-song";
</style>
